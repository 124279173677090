@font-face {
    font-family: "JejuGothic";
    src: url("https://db.onlinewebfonts.com/t/381560810e4c36d16a07abb03254e264.eot");
    src: url("https://db.onlinewebfonts.com/t/381560810e4c36d16a07abb03254e264.eot?#iefix")format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/381560810e4c36d16a07abb03254e264.woff2")format("woff2"),
    url("https://db.onlinewebfonts.com/t/381560810e4c36d16a07abb03254e264.woff")format("woff"),
    url("https://db.onlinewebfonts.com/t/381560810e4c36d16a07abb03254e264.ttf")format("truetype"),
    url("https://db.onlinewebfonts.com/t/381560810e4c36d16a07abb03254e264.svg#JejuGothic")format("svg");
}

body{
  font-family: "JejuGothic";
  color: #7B7B7B;
}

a {
  color: #7B7B7B;
}

.portada-home{
  background-image: url('img/naonel_portada-background_desk.png');
  color: white;
  padding: 0 0 50px;
}

.portada-home header{
  text-align: center;
  padding: 20px 0 0;
}

.portada-home section{
  background: white;
  color: #7B7B7B;
  max-width: 300px;
  margin: 0 auto 0;
  display: block;
  border-radius: 30px;
  padding: 26px 15px 22px 29px;
}

.portada-home section form input{
  width: 90%;
  padding: 10px;
  margin: 10px 0;
  border-radius: 30px;
  border: 0px solid #EDEDED;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
}

.portada-home section form button{
  border: 0px solid #EDEDED;
  background-color: #FFFFFF;
  cursor: pointer;
  float: right;
}

.portada-home section form aside{
  padding: 4px 5px 0;
}

.portada-home section form aside a{
  color: #7B7B7B;
  text-decoration: underline;
}
.portada-home section form aside small{
  padding: 0 10px 0 0;
  font-size: 10px;
}

.bannerGrey{
  background-color: #D8D8D8;
  color: #7B7B7B;
  text-align: center;
  padding: 20px 0;
}

.bannerWhite{
  text-align: center;
  padding: 50px 0;
}

.bannerBlue{
  text-align: center;
  padding: 50px 0;
  background-color: #1BAFDD;
}

.app{
  padding: 20px;
}
  .app header{
    display: flex;
  }
  .app header section{
    border-bottom:1px solid #E3E3E3;
    width: auto;
    max-width: 49%;
  }
  .app header .logo{
    display: flex;
  }
  .app header .logo img{
    max-width: 150px;
  }
  .app header .logo span{
    color: #000;
    font-size: 14px;
    font-weight: lighter;
    text-decoration: none;
    margin: 26px 0 25px 0;
    padding: 7px 0 0 19px;
    border-left: 1px solid #EDEDED;
  }
  .app header .logo span div{
    border-left:1px solid #E3E3E3;
    padding: 16px 0 0 25px;
    min-height: 30px;
  }
  .app header .menu{
    margin-left: auto;
    order: 2;
    display: flex;
    padding: 25px 0 0;
  }
  .app header .menu .menuin{
    padding: 5px 10px 0;
  }
  .app header .menu .menuin a{
    padding: 10px;
    color: #1BAFDD;
  }

/** DashboardAnalytics **/

  .app .dashboard{
    width: 100%;
  }
  .app .dashboard section{
    flex: 1; /* Make the item grow to fill the container */
    padding: 28px 13px;
  }
  .app .dashboard main {
    display: flex;
    width: 100%;
  }
  .app .dashboard .chat{
    text-align: right;
  }
  .app .dashboard .chat section{
    display: block;
  }
  .app .dashboard .chat section img{
    text-align: right;
  }

/** Newsfeed post **/
.app .dashboard .newsfeed .post {
  border-radius: 15px;
  width: 98%;
  min-height: 40px;
  padding: 0 0 0 10px;
  border: 1px solid #EDEDED;
}
.app .dashboard .newsfeedlist main {
  padding: 20px 5px;
  width: 100%;
  border-bottom: 1px solid #EDEDED;
  display: block;
}
.app .dashboard .newsfeedlist main .imagepost{
  width: 100%;
}
.app .dashboard .newsfeedlist main .imagepost img{
  width: 100%;
  height: auto;
  border-radius: 15px;
  border: 1px solid #EDEDED;
}
.app .dashboard .newsfeedlist main .text{
  font-weight: normal;
  padding: 10px 0;
}
.app .dashboard .newsfeedlist main .user{
  font-size: 9px;
}
.app .dashboard .newsfeedlist main .time{
  font-size: 8px;
  font-weight: normal;
}
.app .footer{
  float: right;
  display: flex;
  bottom: 0;
  right: 0;
}
.app .footer div{
  padding: 0 10px;
}
.app .footer div a{
  color: #000;
  text-decoration: none;
}

/** Proyects **/
.app .itemlist main{
  display: flex;
  width: 100%;
}

.app .itemlist main .table-row section{
  display: inline-block;
  width: 30%;
  vertical-align: top;
  padding: 28px 0 0;
}

.app .itemlist main .table-row section:nth-child(1)  {
  border-bottom: 1px solid #EDEDED;
  width: 70%;
  padding: 0;
}

.app .itemlist .itemcolumn{
  width: 30%;
  flex: 1; /* or a specific flex-grow value */
  padding: 10px;
  margin: 5px;
}

.app .itemlist .itemcolumn h2{
  font-size:18px;
  font-weight: bold;
}

.app .itemlist .itemcolumn span{
  font-size:16px;
}

.app .itemlist .itemcolumn small{
  font-size:8px;
}

.app .itemlist .itemcolumn h3{
  font-size: 24px;
  font-weight: lighter;
  color: #000;
  text-align: center;
}

.app .itemlist .itemblock{
  border-right: 1px solid #EDEDED;
  min-height: 500px;
}

.app main .itemcolumn:last-child .itemblock{
  border-right: 0px solid #EDEDED;
}

.dropzone {
  border-radius: 4px; /* Bordes redondeados para suavizar la apariencia */
  margin-bottom: 10px; /* Espacio adicional para separar las columnas */
}

.dragging {
  transition: all 0.2s ease-out;
  transform: scale(1.05);
  opacity: 0.5; /* Reduce opacity of dragging element */
}

.itemblock .hovering {
  background-color: #B9E9F8; /* Change background color of position where element will be dropped */
}

.dropzone.hovering {
  background-color: #B9E9F8; /* Change background color of column when hovered */
}

.placeholder {
  height: 50px; /* Adjust this to match the height of your items */
  border: 2px dashed #ccc; /* A dashed border to indicate the drop position */
  margin: 5px 0; /* Some margin to separate it from other items */
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 300px; /* Adjust width as needed */
  height: 100vh; /* Set sidebar height to full viewport height */
  background-color: #F9F9F9; /* Set background color */
  z-index: 1000; /* Ensure sidebar is above other content */
  transition: transform 0.3s ease; /* Add transition effect */
  transform: translateX(-100%); /* Initially hide the sidebar off-screen */
  padding: 20px;
}

.sidebar.active {
  transform: translateX(0); /* Show sidebar by moving it to the visible position */
}

.sidebar-toggle {
  position: absolute;
  top: 10px;
  left: 10px;
}
.closesidebar{
  float: right;
}
